@import '../mixins'
  
.slideWrapper
  display: flex
  justify-content: center

.chartWrapper
  width: 100%
  height: 100%
  
.swiper
  width: 100%
  height: calc(100% - 23px)
  display: flex
  justify-content: center
  align-items: center

.content
  width: calc(100% - 32px)
  @include tile
  padding: 24px 16px
  display: flex
  flex-direction: column
  gap: 24px
  span
    font-size: 16px

.pagination
  padding-top: 17px
  display: flex
  justify-content: center
  gap: 2px

  .dot
    width: 6px
    height: 6px
    border-radius: 50%
    background: $neutral400
    &.active
      background: $neutral800
