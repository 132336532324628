@import '../mixins'

.cardWrapper
  background: #fff
  border-radius: 12px
  padding: 16px
  position: relative
  white-space: pre-line

  word-wrap: break-word
  overflow-wrap: anywhere
    // white-space: pre-wrap;
  .title
    font-size: 12px
    display: block
    color: $neutral400
