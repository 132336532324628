.headerWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 56px
  padding: 10px 16px
  background: #fff

.errorWrapper
  display: flex
  flex-direction: column
  gap: 16px

.innerWrapper
  width: 100%
  max-width: 500px
  display: flex
  height: 100%
  justify-content: space-between
  align-items: center
  img
    cursor: pointer
    height: 100%

.contentWrapper
  max-width: 500px
  margin: 0 auto
