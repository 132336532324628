.wrapper
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  left: 0
  background: rgba(0, 0, 0, 0.78)
  backdrop-filter: blur(7px)
  z-index: 10000
  padding: 12px 30px
  h2
    background: linear-gradient(139deg, #3D73EB 31.32%, #DE8FFF 78.02%)
    background-clip: text
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    font-family: Arial
    font-size: 36px
    font-style: normal
    font-weight: 700
    line-height: 45px
    letter-spacing: -0.48px
  .buttonsWrapper
    display: flex
    gap: 8px
    position: absolute
    bottom: 12px
    left: 0
    justify-content: center
    padding: 30px
    width: 100vw
    button
      &:active
        background: none
