.toastViewport
  position: fixed
  top: 24px
  display: flex
  flex-direction: column
  gap: 10px
  max-width: 100vw
  width: 100vw
  margin: 0
  list-style: none
  z-index: 2147483647
  outline: none
  padding: 0

.toastWrapper
  box-shadow: 1px 2px 12px 0px rgba(187, 187, 187, 0.44)
  border-radius: 6px
  padding: 15px
  display: grid
  grid-template-areas: 'title action' 'description action'
  grid-template-columns: auto max-content
  column-gap: 15px
  align-items: center
  width: 80vw
  margin-left: 5vw
  &[data-state="open"]
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)
  &[data-state="closed"]
    animation: hide 100ms ease-in
  &[data-swipe='move']
    transform: translateX(var(--radix-toast-swipe-move-x))
  &[data-swipe='cancel']
    transform: translateX(0)
    transition: transform 200ms ease-out
  &[data-swipe='end']
    animation: swipeOut 100ms ease-out
  .title
    grid-area: title
    margin-bottom: 5px
    font-weight: 500
    font-size: 15px
  .description
    grid-area: description
    margin: 0
    font-size: 13px
    line-height: 1.3

  &.variant-default
    background: #fff

  &.variant-message
    background: #afd4eb

  &.variant-error
    background: #d52d2d
    color: #fff 

@keyframes hide 
  from 
    opacity: 1
  to 
    opacity: 0

@keyframes slideIn 
  from 
    transform: translateX(calc(100% + var(--viewport-padding)))
  to 
    transform: translateX(0)
  
@keyframes swipeOut 
  from 
    transform: translateX(var(--radix-toast-swipe-end-x))
  to 
    transform: translateX(calc(100% + var(--viewport-padding)))