@import '../mixins'

.profileListItemTile
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  font-size: 14px
  color: $neutral800

.addWrapper
  @include tile
  height: 56px
  display: flex
  justify-content: center
  align-items: center
  font-size: 14px
  border-radius: 8px
  gap: 8px
  color: $neutral800
  font-weight: 400