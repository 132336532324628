@import '../../ui/mixins'

.actionsWrapper
  display: flex
  flex-direction: column
  gap: 8px
  margin-top: 16px

.formWrapper
  display: flex
  width: 100%
  margin-top: 16px

.searchResultsList
  display: flex
  flex-direction: column
  gap: 8px
  padding-top: 16px

.searchResultsListItem
  @include tile
  gap: 8px
  display: flex
  flex-direction: column

.chipsRow
  display: flex
  gap: 8px
  flex-wrap: wrap

.searchResults
  padding-top: 16px
  padding-bottom: 16px
