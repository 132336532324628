@import '../mixins'
  
.backButton
  display: flex
  font-size: 16px
  align-items: center
  gap: 8px
  font-weight: 500
  position: fixed
  top: 0
  left: 0
  width: 100%
  padding: 24px 16px 12px
  background: $neutral50
  z-index: 100