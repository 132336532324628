@import '../../ui/mixins'

.listWrapper
  width: 100%
  display: flex
  gap: 8px
  flex-direction: column

.tile
  width: 100%
  @include tile
  display: flex
  gap: 16px
  .avatarCol
    width: 42px
  .detailsCol, .infoWrapper
    display: flex
    flex-direction: column

  .detailsCol
    justify-content: center

  .label
    font-size: 14px
    font-weight: 400
    color: $neutral800
  .userInfo
    font-size: 12px
    color: $neutral500
    font-weight: 400

.listTitle
  font-size: 16px
  margin-bottom: 16px
  font-weight: 400
  color: $neutral800
