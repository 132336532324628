@import '../mixins'
  
.cardContent
  display: flex
  flex-direction: column
  flex-basis: 20px
  flex-grow: 1
  flex-shrink: 1
  overflow: hidden
  gap: 24px
  position: relative

.cardRow
  display: flex
  gap: 12px
  flex-direction: column
  .cardLabel
    font-size: 12px
    color: $neutral400
  .cardRowContent
    font-size: 14px
    color: $neutral800

.cardControls
  height: 50px
  display: flex
  justify-content: space-between
  align-items: center

.fade
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 75%)
  height: 50px
  margin-top: -50px
  position: relative
  left: 0
  display: flex
  justify-content: center
  align-items: center

.skipCol
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  font-size: 12px
  color: $neutral400


.rateCardWrapper
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  .inner
    display: flex
    flex-direction: column
    gap: 30px
    align-items: center
    padding: 22px 14px

  .rateTitle
    margin: 0
    font-size: 22px
    color: $neutral800
    text-align: center
  .rateText
    font-size: 16px
    color: $neutral400
    text-align: center
  .buttons
    display: flex
    justify-content: space-between

  .stars
    width: 100%
    display: flex
    justify-content: space-between

  .starsLabel
    font-size: 14px
    color: $neutral400

  .successWrapper
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    height: 100%
    gap: 20px
  
.previewSkeleton
  display: flex
  height: 100%
  flex-direction: column
  justify-content: space-between
  .skeleton
    transform: scale(1,1)

.noResultsWrapper
  display: flex
  flex-direction: column
  gap: 20px
  align-items: center
  justify-content: center
  height: 100%
  padding: 24px 32px 24px 32px
  span
    color: $neutral400
    text-align: center

.likeOverlay
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  background: rgba(109, 118, 114, 0.07)
  backdrop-filter: blur(5px)
  z-index: 100
  border-radius: 12px
  display: flex
  align-items: center
  justify-content: center
