$neutral50: #f7f7f8
$neutral100: #EBEBEF
$neutral400: #8f8fa3
$neutral500: #73738C
$neutral600: #5A5A72
$neutral800: #25252d

$info600: #5F35AE


@mixin tile
  background: #fff
  box-shadow: 1px 2px 12px 0px rgba(187, 187, 187, 0.18)
  border-radius: 12px
  padding: 24px 16px
  