@import '../../ui/mixins'

.startScreenWrapper
  display: flex
  flex-direction: column
  gap: 32px

.navigationTile
  display: flex
  justify-content: space-between
  align-items: center
  height: 80px
  padding: 4px 16px
  box-shadow: 1px 2px 12px 0px rgba(187, 187, 187, 0.18)
  border-radius: 8px

.tilesWrapper
  display: flex
  flex-direction: column
  gap: 8px
  padding-bottom: 94px

.logoWrapper
  display: flex
  justify-content: center
  align-items: center
  gap: 8px

.createProfilePageMenu
  background: $neutral50
  flex-direction: column
  .pageMenuButtons
    display: flex
    gap: 8px
/////// steps

.citiesHints
  display: flex
  gap: 4px
  flex-flow: wrap

.workTypeStepWrapper,
.financialStepWrapper,
.locationControlsWrapper,
.workTypeWrapper
  display: flex
  flex-direction: column
  gap: 16px

.financialStepWrapper
  gap: 24px

.createProfileButtons
  display: flex
  gap: 8px
  margin-top: 12px
  .createProfileButton
    display: flex
    gap: 8px

.firstScreen
  background: #fff

.preloaderBackdrop
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  position: fixed
  z-index: 100
  backdrop-filter: blur(5px)
  display: flex
  align-items: center
  justify-content: center
  .preloaderInner
    width: 80vw
    display: flex
    flex-direction: column
    gap: 24px
    align-items: center
    .preloaderLabel
      text-align: center
      font-weight: 600
