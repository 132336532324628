@import '../mixins'

.stepWrapper
  box-sizing: border-box
  padding: 19px 16px 16px 16px
  display: flex
  align-items: center
  flex-direction: column
  background: #fff
  &.grayBackground
    background: $neutral50

  .startPageControl
    width: 100%
    display: flex
    gap: 12px

    .buttonNext
      height: 48px
      width: 100%

    .buttonBack
      height: 48px
      width: 48px

.progressBarWrapper
  display: flex
  gap: 8px
  width: 100%
  align-items: center
  height: 24px

  .stepperProgress
    height: min-content
    background-color: #EBEBEF
