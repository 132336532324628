@import '../mixins'
  
.userDetailsWrapper
  display: flex
  flex-direction: column
  gap: 24px
  
.detailsHeader
  display: flex
  gap: 24px
  align-items: center
  

.profileInfo
  gap: 8px
  display: flex
  flex-direction: column
  justify-content: space-between

.profileTitle
  font-size: 16px !important
  font-weight: 600

.location
  color: $neutral400
  display: flex
  gap: 2px

.locationIcon
  width: 16px !important
  height: 16px !important

.workType
  display: flex
  align-items: center
  gap: 4px

.profileBottomLineWrapper
  display: flex
  gap: 2px
  flex-wrap: wrap
  flex-direction: column

.personName
  color: $neutral800

.tags
  display: flex
  flex-wrap: wrap
  gap: 8px


.subheader
  display: flex
  gap: 15px

  .inner
    display: flex
    text-align: center
    flex-direction: column
    gap: 8px

    .bottomLabel
      font-size: 10px
      color: $neutral400

  .similarity
    width: 90px
    border-right: 1px solid $neutral100
    .inner
      width: 74px
      display: flex
      flex-direction: column
      gap: 8px
    
    &.fullWidth
      border: none
    
    .similarityLevel
      font-size: 14px
      color: $neutral800

  .financialInfo
    flex: 1
    .price
      display: flex
      align-items: flex-end
      justify-content: center
      gap: 6px
      font-size: 12px
      color: $neutral400
      .primary
        font-size: 14px
        color: $neutral800