@import '../../ui/mixins'

.navigationItemContent
  color: $neutral400
  display: flex
  flex-direction: column
  align-items: center
  font-size: 12px
  gap: 4px

  &.active
    color: $neutral800

  .navigationItemIcon
    width: 24px
    height: 24px

