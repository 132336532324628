.avatarWrapper
  border-radius: 50%
  background-size: cover
  background-attachment: center
  background-repeat: no-repeat
  border: 1px solid #eee
  position: relative
  background-color: #fff

.like
  position: absolute
  right: 0
  top: 0
  width: 24px
  height: 24px
  display: flex
  justify-content: center
  align-items: center
  background: #fff
  border-radius: 50%
  box-shadow: 3px 8px 11px 0px rgba(187, 187, 187, 0.08), 4px 4px 12px 2px rgba(187, 187, 187, 0.14)
