.bottomNavigationMenu
  display: flex
  position: fixed
  left: 0
  bottom: 0
  height: 70px
  background: #fff
  width: 100%
  gap: 8px
  z-index: 9999
