@import '../../ui/mixins'

.profilesWrapper
  display: flex
  flex-direction: column
  gap: 8px

.profileTile
  width: 100%
  @include tile
  display: flex
  flex-direction: column
  gap: 8px

.profilesList
  display: flex
  flex-direction: column
  gap: 8px

.lastDayProfiles
  display: flex
  flex-direction: column
  gap: 8px
  padding-bottom: 8px

.truncatedLabel
  display: flex
  flex-direction: row
  gap: 8px
  align-items: center
  &>div
    font-weight: bold
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
