.root
  width: 100%
  display: flex
  gap: 8px
  min-height: 48px
  position: fixed
  bottom: 0
  left: 0
  padding: 8px 16px 24px 16px
  background: #fff
  z-index: 10000000
