.imageContainer
  height: 100vh
  background: #242121

.imagePreview
  width: 60vw
  height: 60vw
  background-size: cover !important
  background-repeat: no-repeat
  border-radius: 50%
  background: #ebebef

.input
  display: none

.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 8px

.uploadImage
  color: #054da7
  cursor: pointer

.cropperWrapper
  position: fixed
  top: 0
  left: 0
  z-index: 1000000
  width: 100vw
  height: 100vh

.canvas
  display: none
