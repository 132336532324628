@import '../mixins'

.cardWrapper
  width: 100%
  @include tile
  display: flex
  flex-direction: column
  gap: 4px
  z-index: 2
  position: absolute
  height: 100%

.stackWrapper
  display: flex
  position: relative
  width: 100%
  flex-grow: 1

  .bottomCard
    position: absolute
    display: flex
    background: red
    width: 100%
    height: 100%
    top: 8px
    z-index: 1
    @include tile

.animateHideLike
  animation-name: animateHideLike
  animation-duration: .5s

@keyframes animateHideLike
  50%
    transform: translateY(0)
  100%
    transform: translateY(-800px)

.animateHideSkip
  animation-name: animateHideSkip
  animation-duration: .5s

@keyframes animateHideSkip
  100%
    transform: translateY(-800px)

.newCardAnimate
  animation-name: newCardAnimation
  animation-duration: .3s

@keyframes newCardAnimation
  to
    transform: translateY(0px) scale(1)

.bottom
  transform: translateY(20px) scale(.95)

.matchModalButtons
  padding-top: 20px
  display: flex
  flex-direction: column
  gap: 8px

.avatars
  display: flex
  margin-top: -67px
  justify-content: center
  position: relative

.matchLabel
  color: $info600 !important
  padding-top: 16px
  padding-bottom: 12px

.matchText
  padding-top: 12px

.matchContent
  display: flex
  flex-direction: column
  gap: 8px
