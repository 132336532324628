@import '../../ui/mixins'

.profilesWrapper
  display: flex
  flex-direction: column
  gap: 8px
  .profileTile
    width: 100%
    @include tile
    display: flex
    flex-direction: column
    gap: 8px
