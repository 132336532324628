@import '../../ui/mixins'

.pageLayout
  min-height: calc(100% - 40vh - 104px)
  padding-bottom: 106px
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 16px
  &.fullHeight
    min-height: calc(100% - 104px)

.tiles
  display: flex
  flex-direction: column
  gap: 16px

  .tile
    display: flex
    background: white
    padding: 10px
    @include tile
    &.historyTile
      display: flex
      justify-content: space-between

.incomingLikesTile
  display: flex
  flex-direction: column
  gap: 24px

.avatarsGroup
  display: flex
  justify-content: space-between
  .button
    display: flex
    align-items: center

.archivedInfo
  text-transform: uppercase
  font-size: 13px
  font-weight: 500
  text-align: center

.profileWrapper
  @include tile
  margin: 24px 16px 0 16px
  padding: 16px
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 12px

  .profileDetails
    display: flex
    flex-direction: column
    gap: 4px
    justify-content: center
    align-items: flex-start
    flex: 1
    .positionName
      font-size: 14px
      color: $neutral800
      font-weight: 400
    .profileVariant
      font-size: 10px
      color: $neutral400
      font-weight: 400

  .publicInner
    display: flex
    flex-direction: column
    gap: 12px
    width: 100%
    .publicRow
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      gap: 12px

  .openButton
    display: flex
    align-items: center
