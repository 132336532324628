@import '../../ui/mixins'

.sectionHeader
  display: flex
  gap: 8px
  font-size: 16px
  color: $neutral800

.notActive
  color: $neutral400

.sectionsWrapper
  display: flex
  flex-direction: column
  gap: 24px

.section
  display: flex
  gap: 16px
  flex-direction: column

.stepperBottomMenu
  height: min-content
  padding-bottom: 8px
  background: #f7f7f8
