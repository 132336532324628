@import '../mixins'

.profileWrapper
  display: flex
  flex-direction: column
  gap: 12px
  flex: 1

.detailsCardText
  font-size: 14px

.tags
  display: flex
  flex-flow: wrap
  gap: 8px

.reportWrapper
  position: fixed
  top: 0
  left: 0
  z-index: 100
  min-height: 100vh
  width: 100vw
  background: $neutral50

  .reportReasons
    display: flex
    flex-direction: column
    gap: 16px
    height: max-content
    padding-top: 16px

  .title
    color: $neutral800
    font-weight: bold
    font-size: 20px

  .reportButtons
    display: flex
    flex-direction: column
    gap: 8px

.tagsOverlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #fff
  z-index: 100
  overflow: auto
  padding: 16px
  .tagsHeader
    display: flex
    align-items: center
    justify-content: space-between
  .tagsWrapper
    display: flex
    padding: 16px 0
    gap: 8px
    flex-wrap: wrap

.editableProfileWrapper
  display: flex
  flex-direction: column
  gap: 24px
